import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { env } from "../../../env";
import ReconnectingWebSocket from "reconnecting-websocket";

const initialState = {
  ws: null,
};

const connect = (userId) =>
  new Promise((res, rej) => {
    let origin = window.location.origin;
    if(origin.includes("localhost")){
      origin = "http://localhost:8080"
    }
    try {
      const ws = new ReconnectingWebSocket(`${origin.includes("https://")?"wss://":"ws://"}${origin.split("://")[1]}/api/socket`);
      ws.onopen=()=>{
        console.log("%cConnected !", "color:green");
        ws.send(JSON.stringify({ type: "init", content: userId }))
        return res(ws);
      }     
    } catch (err) {
      console.log("%cConnected error !", "color:red");
      return rej(err);
    }
  });

export const connectSocket = createAsyncThunk("SocketConnect", async (userId,thunk) => {
  return await connect(userId);
});

const socketReducer = createSlice({
  name: "SocketReducer",
  initialState,
  reducers: {
    updateTableSocket: (state, { payload }) => {
      state.ws.send(JSON.stringify({ type: "update", content: payload }));
    },
    //update menu registry
    updateRegistryMenu:(state)=>{
      state.ws.send(JSON.stringify({ type: "update", content: "registryMenu" }));
    },
    setSocket:(state,{payload})=>{
      state.ws = payload;
    }
  },
  extraReducers: {
    [connectSocket.fulfilled]: (state, { payload }) => {
      state.ws = payload;
    },
  },
});

export const { updateTableSocket, updateRegistryMenu } = socketReducer.actions;
 
export default socketReducer.reducer;
