import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { useEffect, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
const RentContractFormStepTwo = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("0.00");
  useEffect(() => {
    const fetchData = async () => {
      const servicePriorityContractFetch = GlobalAuthFetch({
        path: "/dictionary/servicePriorityContract/fieldName",
      });
      const [servicePriorityContractPayload] = await Promise.all([
        servicePriorityContractFetch,
      ]);
      setServicePriorityContractSelect(
        servicePriorityContractPayload?.payload?.data?.dictionaryEntries
      );
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  const [servicePriorityContractSelect, setServicePriorityContractSelect] =
    useState([]);
  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:rentContract.more")} />
      <Row>
        <Col xs={2}>
          <Field
            name="accountDay"
            label={`${t("global:rentContract.accountDay")}`}
            component={BMXTextField}
            data-cy="accountDay"
            value={value}
            step={0.5}
            onChange={(e) => setValue(parseFloat(e.target.value).toFixed(1))}
            InputProps={{
              maxLength: 13,
              step: "1",
              endAdornment: (
                <InputAdornment position="end">
                  <span style={{ fontSize: "12px" }}>PLN</span>
                </InputAdornment>
              ),
            }}
            type="number"
          />
        </Col>
        <Col xs={{ span: 3 }}>
          <Field
            name="transferDay"
            data-cy="transferDay"
            component={BMXTextField}
            label={t("global:rentContract.transferDay")}
          />
        </Col>
        <Col xs={{ span: 3 }}>
          <Field
            name="accountReceiverEmail"
            label={`${t("global:rentContract.accountReceiverEmail")}`}
            component={BMXTextField}
            data-cy="accountReceiverEmail"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 3 }}>
          <Field
            component={BMXSelectField}
            values={servicePriorityContractSelect
              ?.sort((a, b) => a.entryName.localeCompare(b.entryName))
              ?.map((el) => ({
                name: el.entryName,
                value: el.uuid,
              }))}
            label={`${t("global:rentContract.servicePriorityContract")}`}
            name="servicePriorityContract"
            data-cy="servicePriorityContract"
          />
        </Col>
        <Col xs={2}>
          <Field
            name="deployCost"
            label={`${t("global:rentContract.deployCost")}`}
            component={BMXTextField}
            data-cy="deployCost"
            type="number"
          />
        </Col>
      </Row>
    </div>
  );
};

export default RentContractFormStepTwo;
