import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";

import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const RentContractFormStepTwo = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:rentContract.comments")} />
      <Row>
        <Col xs={12}>
          <Field
            name="contractComments"
            label={`${t("global:rentContract.contractComments")}`}
            component={BMXTextField}
            data-cy="contractComments"
            maxRows={4}
            multiline={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RentContractFormStepTwo;
